import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { createContext, useCallback, useEffect } from 'react';
import useLocalStorageState from 'use-local-storage-state';

const default_values = {
	clearChannel: () => {},
	getAllChannels: () => {},
	getLatestChannelId: () => {},
};
const local_storage_key = 'channel';
const cleanup_time = 6 * 30 * 24 * 60 * 60 * 1000; // 6 months in milliseconds

export const ChannelContext = createContext(default_values);

export const ChannelProvider = ({ children, location }) => {
	const { channel: channel_parameter } = queryString.parse(location.search);
	const [channel, setChannel] = useLocalStorageState(local_storage_key);

	const addChannel = useCallback(
		(id) => {
			setChannel((current) => {
				const currentTime = Date.now();
				if (!current?.length) current = [];
				const filtered = current
					.filter((item) => item.id !== id)
					.filter((item) => currentTime - item.time < cleanup_time);

				const newItem = { id, time: new Date().getTime() };
				return [newItem, ...filtered];
			});
		},
		[setChannel],
	);

	const clearChannel = () => {
		setChannel([]);
	};

	const getAllChannels = () => {
		if (channel?.length) {
			return channel.map((item) => ({
				channel_id: item.id,
				tracked_at: item.time,
			}));
		}
		return undefined;
	};

	const getLatestChannelId = () => {
		if (channel?.length && channel[0].id) {
			const check = Number(channel[0].id);
			if (!isNaN(check)) return channel[0].id;
		}
		return undefined;
	};

	// Convert from old format
	useEffect(() => {
		if (channel?.id) {
			setChannel([channel]);
		}
	}, [channel, setChannel]);

	useEffect(() => {
		if (!channel_parameter) return;
		else if (channel?.length && channel[0].id == channel_parameter) return;
		addChannel(channel_parameter);
	}, [addChannel, channel, channel_parameter]);

	return (
		<ChannelContext.Provider
			value={{
				clearChannel,
				getAllChannels,
				getLatestChannelId,
			}}
		>
			{children}
		</ChannelContext.Provider>
	);
};

ChannelProvider.propTypes = {
	children: PropTypes.object,
	location: PropTypes.object,
};
