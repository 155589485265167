import PropTypes from 'prop-types';
import React, { createContext, useEffect, useMemo, useState } from 'react';
import useLocalStorageState from 'use-local-storage-state';

import Suspense from '../components/framework/suspense';
import useCache from '../hooks/use_cache';
import useMutate from '../hooks/use_mutate';
import useRequest from '../hooks/use_request';

const default_values = {
	addItem: async () => {},
	amount: {},
	cartId: undefined,
	checkDiscountCode: async () => {},
	countItems: 0,
	currency: {},
	emptyCart: async () => {},
	forgetCart: async () => {},
	isItemInCart: () => ({ item: {}, status: 'no' }),
	items: [],
	removeItem: async () => {},
	replaceCart: async () => {},
	revalidate: async () => {},
	setCart: () => {},
	shareCart: async () => {},
};
const local_cart_id_key = 'cart_id';
const cart_item_source = {
	addon: 6,
	campaign: 7,
	cart: 8,
	catalog: 1,
	group: 12,
	index: 5,
	item: 2,
	price_list: 9,
	related: 4,
	search: 3,
	share: 11,
};

export const StoreContext = createContext(default_values);

const Request = ({ cartId, setCart }) => {
	const { data: cartData } = useRequest(cartId ? 'shop.cart' : null, {
		body: {
			cart_id: cartId,
		},
	});

	useEffect(() => {
		if (cartData?.data) {
			setCart(cartData.data);
		}
	}, [cartData, setCart]);

	return <></>;
};

export const StoreProvider = ({ children }) => {
	const { revalidate: revalidateCache } = useCache();

	const [cart, setCart] = useState([]);
	const [cartId, setCartId, { removeItem: removeCartId }] =
		useLocalStorageState(local_cart_id_key);
	const { trigger: triggerDiscountCode } = useMutate('discount_code.check');
	const { trigger: triggerItemAdd } = useMutate('shop.item_add');
	const { trigger: triggerItemEmpty } = useMutate('shop.empty');
	const { trigger: triggerItemRemove } = useMutate('shop.item_remove');
	const { trigger: triggerReplace } = useMutate('shop.replace');
	const { trigger: triggerShare } = useMutate('shop.share');

	const addItem = async ({
		attributes,
		channel_id,
		product_variant_id,
		source,
	}) => {
		const result = await triggerItemAdd({
			attributes,
			cart_id: cartId,
			channel_id,
			product_variant_id,
			quantity: 1,
			source: cart_item_source[source] ?? undefined,
		});

		if (result?.status == 'ok' && cartId != result?.data?.cart_id) {
			setCartId(result.data.cart_id);
		}

		await revalidate();
		return result;
	};

	const amount = useMemo(() => {
		return cart?.amount;
	}, [cart.amount]);

	const checkDiscountCode = async ({ code }) => {
		const result = await triggerDiscountCode({
			cart_id: cartId,
			code: code,
		});
		if (result?.status != 'ok') return false;

		await revalidate();
		return true;
	};

	const countItems = useMemo(() => {
		const data = cart?.items?.filter(
			(item) => item.state != 'hidden' && item?.cart?.status != 'replaced',
		);
		return data?.length ?? 0;
	}, [cart.items]);

	const currency = useMemo(() => {
		return cart?.currency;
	}, [cart.currency]);

	const emptyCart = async () => {
		await triggerItemEmpty({ cart_id: cartId });
		await revalidate();
	};

	const forgetCart = async () => {
		removeCartId();
	};

	const isItemInCart = ({ markers, product_id, product_settings }) => {
		let out = { status: 'no' };

		const check = cart?.items?.find((value) => value.product_id == product_id);
		if (check?.cart?.status == 'replaced') {
			out.status = check.cart.status;
		} else if (check) {
			out.status = 'yes';
		} else if (
			markers &&
			markers.length == 1 &&
			cart?.items?.find((value) => value?.markers?.includes(markers[0]))
		) {
			// Marker exists in another product
			out.item = cart?.items?.findLast((value) =>
				value.markers.includes(markers[0]),
			);
			out.status = 'other';
		} else if (
			!markers?.length &&
			product_settings?.include_statement &&
			cart?.items?.find((value) => value?.product_settings?.include_statement)
		) {
			out.status = 'replaced';
		}

		return out;
	};

	const items = useMemo(() => {
		return cart?.items;
	}, [cart.items]);

	const removeItem = async ({ product_variant_id }) => {
		const result = await triggerItemRemove({
			cart_id: cartId,
			product_variant_id,
		});
		await revalidate();
		return result;
	};

	const replaceCart = async (source_cart_id) => {
		const result = await triggerReplace({
			destination_cart_id: cartId,
			source_cart_id,
		});

		if (result?.status == 'ok' && cartId != result?.data?.destination_cart_id) {
			setCartId(result.data.destination_cart_id);
		}

		await revalidate();
	};

	const revalidate = async () => {
		await revalidateCache('order.create');
		await revalidateCache('shop.cart');
	};

	const shareCart = async () => {
		return await triggerShare({ cart_id: cartId });
	};

	return (
		<StoreContext.Provider
			value={{
				addItem,
				amount,
				cart,
				cartId,
				checkDiscountCode,
				countItems,
				currency,
				emptyCart,
				forgetCart,
				isItemInCart,
				items,
				removeItem,
				replaceCart,
				revalidate,
				shareCart,
			}}
		>
			{children}
			<Suspense>
				<Request cartId={cartId} setCart={setCart} />
			</Suspense>
		</StoreContext.Provider>
	);
};

Request.propTypes = {
	cartId: PropTypes.string,
	setCart: PropTypes.func,
};

StoreProvider.propTypes = {
	children: PropTypes.object,
};
