import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import useTranslation from '../../hooks/use_translation';
import { getVariantByLanguage } from '../../utils/helpers';
import { createEvent } from '../../utils/tracking';
import GroupLink from '../framework/group_link';

const ArticleTag = ({
	allergen_id,
	article_id,
	marker_id,
	product_id,
	symptom_id,
	title,
}) => {
	let article = null;
	let marker = null;
	let product = null;
	const { language } = useTranslation('component_articles_tag');

	const result = useStaticQuery(graphql`
		query ArticleTagComponent {
			allArticle {
				nodes {
					article_id
					article_variants {
						language {
							code
						}
						slug
						title
					}
					groups
				}
			}

			allGroup {
				nodes {
					group_id
					type
				}
			}

			allMarker {
				nodes {
					groups
					marker_id
					marker_variants {
						language {
							code
						}
						slug
						title
					}
				}
			}

			allNewProduct {
				nodes {
					groups
					product_id
					product_variants {
						language {
							code
						}
						slug
						title
					}
				}
			}
		}
	`);

	const handleTracking = ({ id, title, type }) => {
		createEvent('button_tag', {
			id,
			title,
			type,
		});
	};

	if (allergen_id) {
		allergen_id = parseInt(allergen_id);
		marker = result.allMarker.nodes.find(
			(value) => value.marker_id == allergen_id,
		);
		if (!marker) return <></>;

		const marker_variant = getVariantByLanguage(
			language,
			marker.marker_variants,
		);
		if (!marker_variant) return <></>;

		const allergen_group = result.allGroup.nodes.find(
			(item) => marker.groups.includes(item.group_id) && item.type == 'marker',
		);

		return (
			<GroupLink
				groups={[allergen_group.group_id]}
				marker_id={marker.marker_id}
				onClick={() =>
					handleTracking({
						id: marker.marker_id,
						title: title || marker_variant.title,
						type: 'allergen',
					})
				}
				page="allergener"
			>
				{title || marker_variant.title}
			</GroupLink>
		);
	} else if (article_id) {
		article_id = parseInt(article_id);
		article = result.allArticle.nodes.find(
			(value) => value.article_id == article_id,
		);
		if (!article) return <></>;

		const article_variant = getVariantByLanguage(
			language,
			article.article_variants,
		);
		if (!article_variant) return <></>;

		return (
			<GroupLink
				article_id={article.article_id}
				groups={article.groups}
				onClick={() =>
					handleTracking({
						id: article.article_id,
						title: title || article_variant.title,
						type: 'article',
					})
				}
				page="artiklar"
			>
				{title || article_variant.title}
			</GroupLink>
		);
	} else if (marker_id) {
		marker_id = parseInt(marker_id);
		marker = result.allMarker.nodes.find(
			(value) => value.marker_id == marker_id,
		);
		if (!marker) return <></>;

		const marker_variant = getVariantByLanguage(
			language,
			marker.marker_variants,
		);
		if (!marker_variant) return <></>;

		const marker_group = result.allGroup.nodes.find(
			(item) => marker.groups.includes(item.group_id) && item.type == 'marker',
		);

		return (
			<GroupLink
				groups={[marker_group.group_id]}
				marker_id={marker.marker_id}
				onClick={() =>
					handleTracking({
						id: marker.marker_id,
						title: title || marker_variant.title,
						type: 'marker',
					})
				}
				page="markorer"
			>
				{title || marker_variant.title}
			</GroupLink>
		);
	} else if (product_id) {
		product_id = parseInt(product_id);
		product = result.allNewProduct.nodes.find(
			(value) => value.product_id == product_id,
		);
		if (!product) return <></>;

		const product_variant = getVariantByLanguage(
			language,
			product.product_variants,
		);
		if (!product_variant) return <></>;

		return (
			<GroupLink
				groups={
					product?.default?.group_id
						? [product?.default?.group_id]
						: product.groups
				}
				onClick={() =>
					handleTracking({
						id: product.product_id,
						title: title || product_variant.title,
						type: 'product',
					})
				}
				page="tester"
				product_id={product.product_id}
			>
				{title || product_variant.title}
			</GroupLink>
		);
	} else if (symptom_id) {
		symptom_id = parseInt(symptom_id);
		article = result.allArticle.nodes.find(
			(value) => value.article_id == symptom_id,
		);
		if (!article) return <></>;

		const article_variant = getVariantByLanguage(
			language,
			article.article_variants,
		);
		if (!article_variant) return <></>;

		return (
			<GroupLink
				groups={article.groups}
				onClick={() =>
					handleTracking({
						id: article.article_id,
						title: title || article_variant.title,
						type: 'symptom',
					})
				}
				page="symtom"
				symptom_id={article.article_id}
			>
				{title || article_variant.title}
			</GroupLink>
		);
	}

	return <></>;
};

ArticleTag.propTypes = {
	allergen_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	article_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	marker_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	product_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	symptom_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	title: PropTypes.string,
};

export default ArticleTag;
