import '@popperjs/core/dist/umd/popper.min.js';
import bootstrap from 'bootstrap/dist/js/bootstrap.min.js';
import 'core-js/stable';
import React from 'react';
import 'react-phone-input-2/lib/bootstrap.css';
import { StatsigProvider } from 'statsig-react';

import { ChannelProvider } from './src/context/channel';
import { StoreProvider } from './src/context/store';
import './src/libs/monitoring';
import './src/css/custom.scss';
import 'react-toastify/dist/ReactToastify.css';

const tooltipTriggerList = [].slice.call(
	document.querySelectorAll('[data-bs-toggle="tooltip"]'),
);

tooltipTriggerList.map(function (tooltipTriggerEl) {
	return new bootstrap.Tooltip(tooltipTriggerEl);
});

export const onRouteUpdate = ({ location, prevLocation }) => {
	if (location?.state) {
		location.state.previous_pathname = prevLocation
			? prevLocation.pathname
			: null;
	}
};

export const shouldUpdateScroll = ({ prevRouterProps, routerProps }) => {
	const nextSearch = routerProps?.location.search || '';
	const prevSearch = prevRouterProps?.location.search || '';

	const getPageParam = (search) => {
		const params = new URLSearchParams(search);
		return params.get('page');
	};

	const prevPage = getPageParam(prevSearch);
	const nextPage = getPageParam(nextSearch);

	const samePath =
		prevRouterProps?.location.pathname === routerProps.location.pathname;

	// If path is the same and only the page param changed → prevent scroll
	if (samePath && prevPage !== nextPage) {
		return false;
	}

	return true;
};

export const wrapPageElement = ({ element, props }) => {
	const { location } = props;
	return <ChannelProvider location={location}>{element}</ChannelProvider>;
};

export const wrapRootElement = ({ element }) => {
	return (
		<StatsigProvider
			options={{
				environment: {
					tier:
						process.env.NODE_ENV == 'production' ? 'production' : 'development',
				},
			}}
			sdkKey="client-z5TGduE9RJR8NsMjbPthjPyFHdwsK5cLhPzP7GSzxse"
		>
			<StoreProvider>{element}</StoreProvider>
		</StatsigProvider>
	);
};
