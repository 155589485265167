export function calculateDistance(
	latitude1,
	longitude1,
	latitude2,
	longitude2,
) {
	if (latitude1 == latitude2 && longitude1 == longitude2) {
		return 0;
	}

	let radius_latitude1 = (Math.PI * latitude1) / 180;
	let radius_latitude2 = (Math.PI * latitude2) / 180;
	let theta = longitude1 - longitude2;
	let radius_theta = (Math.PI * theta) / 180;

	let distance =
		Math.sin(radius_latitude1) * Math.sin(radius_latitude2) +
		Math.cos(radius_latitude1) *
			Math.cos(radius_latitude2) *
			Math.cos(radius_theta);
	if (distance > 1) distance = 1;

	distance = Math.acos(distance);
	distance = (distance * 180) / Math.PI;
	distance = distance * 60 * 1.1515;
	distance = distance * 1.609344; // kilometers

	return distance;
}

export function createSlug(text) {
	return text
		.toString()
		.normalize('NFKD')
		.toLowerCase()
		.trim()
		.replace(/\s+/g, '-')
		.replace(/[^\w-]+/g, '')
		.replace(/_/g, '-')
		.replace(/--+/g, '-')
		.replace(/-$/g, '');
}

export function formatAmount(data, options = {}, locale = 'sv-SE') {
	const defaultOptions = {
		currency: 'SEK',
		minimumFractionDigits: 0,
		style: 'currency',
		trailingZeroDisplay: 'stripIfInteger',
	};
	options = { ...defaultOptions, ...options };

	return new Intl.NumberFormat(locale, options).format(data);
}

export function formatNumber(data, options = {}) {
	const defaultOptions = {
		locale: 'sv-SE',
	};
	options = { ...defaultOptions, ...options };

	const formatter = new Intl.NumberFormat(options.locale, options);
	return formatter.format(data);
}

export function getGroupsByMarker({
	fallback_group = null,
	groups,
	markers,
	selected_id,
}) {
	let out = { current: {}, items: [] };

	if (fallback_group && !fallback_group.group_id) {
		fallback_group.group_id = 0;
	}

	for (const marker of markers) {
		if (!marker?.groups?.length) {
			if (fallback_group && !out.items[fallback_group.group_id]) {
				out.items[fallback_group.group_id] = fallback_group;
			}
			continue;
		}

		for (const group_id of marker.groups) {
			const group = groups.find((value) => value.group_id == group_id);
			if (!group) continue;
			if (!group?.group_variant) continue;

			out.items[group_id] = { ...group.group_variant, group_id };

			if (group_id == selected_id || (!selected_id && !out.current.group_id)) {
				out.current = group_id;
			}
		}
	}

	out.items = Object.values(out.items);
	out.items = sortObject(out.items, { key: 'title', order: 'asc' });

	return out;
}

export function getGroupsByProduct({
	groups,
	language,
	products,
	selected_id,
}) {
	let out = { current: {}, items: {} };

	for (const product of products) {
		if (!product.groups || !product.groups.length) continue;

		for (const group_id of product.groups) {
			const group = groups.find((value) => value.group_id == group_id);
			if (!group) continue;

			const variant = getVariantByLanguage(language, group.group_variants);
			if (!variant) continue;

			out.items[group_id] = variant;

			if (group_id == selected_id || (!selected_id && !out.current.group_id)) {
				out.current = {
					group_id,
					variant,
				};
			}
		}
	}

	return out;
}

export function getProductGroup(groups, product) {
	let group = null;
	if (!groups || !product) return group;

	if (product?.default?.group_id) {
		group = groups.find((value) => value.group_id == product.default.group_id);
	}
	if (!group && product.groups && product.groups.length) {
		group = groups.find((value) => value.group_id == product.groups[0]);
	}

	return group;
}

export function getVariantByLanguage(language, variant) {
	if (!variant) return null;
	return variant.find((value) => value.language.code == language);
}

export function handleTableOfContent(data) {
	let count = 1;
	const headerRegex = /<((h[2-3])\s*([^>]*))>(.*?)<\/h[2-3]>/gm;
	const idRegex = /id="(.*?)"/gm;
	let match = null;
	let items = [];

	while ((match = headerRegex.exec(data)) !== null) {
		let id = null;
		idRegex.lastIndex = 0;

		if (match[3]) {
			const check = idRegex.exec(match[3]);
			if (check && check[1]) id = check[1];
		}

		if (!id) {
			id = `index-${count++}`;
			data = [
				data.slice(0, match.index + 3),
				` id="${id}"`,
				data.slice(match.index + 3),
			].join('');
		}

		if (id && match[4]) {
			items.push({ id, text: match[4] });
		}
	}

	return { content: data, items };
}

export function handleTagComponent(data) {
	let attribute = null;
	let attributes = [];
	const attributeRegex =
		/(\S+)\s*=\s*["']?((?:.(?!["']?\s+(?:\S+)=|[>"']))?[^"']*)["']?/gm;
	const tagRegex = /<((span data-type="tag")\s*([^>]*))>(.*?)<\/span>/gm;
	let ignore_attributes = ['data-type'];
	let match = null;

	while ((match = tagRegex.exec(data)) !== null) {
		if (!match[0]) continue;

		attributes = [];
		attributeRegex.lastIndex = 0;

		while ((attribute = attributeRegex.exec(match[0])) !== null) {
			if (ignore_attributes.includes(attribute[1])) continue;
			attributes.push(`${attribute[1]}="${attribute[2]}"`);
		}
		if (!attributes || !attributes.length) continue;

		data = [
			data.slice(0, match.index),
			`<Tag ${attributes.join(' ')}/>`,
			data.slice(match.index + match[0].length),
		].join('');
	}

	return data;
}

export function sortObject(data, property) {
	let sortFunction = sortObjectByKey;

	if (Array.isArray(property?.order) && property?.order?.length) {
		sortFunction = sortObjectByList;
	}

	return data.sort(sortFunction(property));
}

function sortObjectByKey(property) {
	let order = 1;
	if (property?.order === 'desc') {
		order = -1;
	}
	return function (a, b) {
		let result =
			a[property.key] < b[property.key]
				? -1
				: a[property.key] > b[property.key]
					? 1
					: 0;
		return result * order;
	};
}

function sortObjectByList(property) {
	return function (a, b) {
		const indexA =
			property.order.indexOf(a[property.key]) !== -1
				? property.order.indexOf(a[property.key])
				: Infinity;
		const indexB =
			property.order.indexOf(b[property.key]) !== -1
				? property.order.indexOf(b[property.key])
				: Infinity;
		return indexA - indexB;
	};
}
